import { Component, NgZone } from '@angular/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Router } from '@angular/router';
import { register } from 'swiper/element/bundle';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './utils/theme.service'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(
		private screenOrientation: ScreenOrientation,
		private router: Router,
		private zone: NgZone,
		private translate: TranslateService,
		public theme: ThemeService
	) {
		this.translate.addLangs(['es', 'en']);
		this.translate.setDefaultLang('es');
		if (localStorage.getItem('language')) {
			var language = localStorage.getItem('language');
		} else {
			localStorage.setItem('language', 'es');
		}
		this.theme.setPreferenceTheme();

		// if (localStorage.getItem('colorTheme')) {
		// 	var colorTheme = localStorage.getItem('colorTheme');
		// } else {
		// 	this.theme.setPreferenceTheme(colorTheme);
		// 	localStorage.setItem('colorTheme', 'dark');
		// }

		this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT).catch(error => { console.error(error); });
		register();
		if (language !== '') {
			this.translate.use(language);
			console.log('current-lang' + this.translate.currentLang);
		} else {
			this.translate.use('es');
			console.log('current-lang' + this.translate.currentLang);
		}
	}

}
